







import { defineComponent } from '@nuxtjs/composition-api'

import useLayoutCommon from '@/composables/use-layout-common'
import useDefaultMeta from '@/composables/use-default-meta'
import useI18n from '@/composables/use-i18n'

export default defineComponent({
  head: {},
  setup () {
    const i18n = useI18n()

    useLayoutCommon()

    useDefaultMeta({
      title: String(i18n.t('head.index.title')),
      description: String(i18n.t('head.index.description')),
    })
  },
})
