import { render, staticRenderFns } from "./OrderList.vue?vue&type=template&id=10cf6378&scoped=true&lang=pug&"
import script from "./OrderList.vue?vue&type=script&lang=ts&"
export * from "./OrderList.vue?vue&type=script&lang=ts&"
import style0 from "./OrderList.vue?vue&type=style&index=0&id=10cf6378&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10cf6378",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrderTableHeader: require('/app/components/order/table/OrderTableHeader.vue').default,OrderTableRowAlive: require('/app/components/order/table/row/OrderTableRowAlive.vue').default,OrderTableRowDelivered: require('/app/components/order/table/row/OrderTableRowDelivered.vue').default,OrderTableRowComplete: require('/app/components/order/table/row/OrderTableRowComplete.vue').default,OrderTableRowCanceled: require('/app/components/order/table/row/OrderTableRowCanceled.vue').default,OrderTableRowHold: require('/app/components/order/table/row/OrderTableRowHold.vue').default,OrderTableBody: require('/app/components/order/table/OrderTableBody.vue').default,OrderCard: require('/app/components/order/OrderCard.vue').default,OrderChangeInvoice: require('/app/components/order/OrderChangeInvoice.vue').default,OrderGetReview: require('/app/components/order/OrderGetReview.vue').default,OrderGetRefundDetail: require('/app/components/order/OrderGetRefundDetail.vue').default,OrderListDetail: require('/app/components/order/OrderListDetail.vue').default,GModal: require('/app/components/GModal.vue').default,GAlert: require('/app/components/GAlert.vue').default,GLoading: require('/app/components/GLoading.vue').default})
