import OrderStatus from '@/enums/OrderStatus'
import { Ref, computed, ref, watch } from '@nuxtjs/composition-api'
import useI18n from '@/composables/use-i18n'
import useOrder from '@/composables/use-order'
import PaymentType from '@/enums/PaymentType'
import Order from '@/classes/Order'
import PaymentStatus from '~/enums/PaymentStatus'

export default function (statusRef: Ref<OrderStatus>) {
  const { getOrders } = useOrder()

  const i18n = useI18n()

  const headers = computed(() => {
    switch (statusRef.value) {
      case OrderStatus.ALIVE:
        return [
          i18n.t('orderDetail.order.number'),
          i18n.t('orderDetail.order.time'),
          i18n.t('orderDetail.order.car'),
          i18n.t('orderDetail.order.store'),
          i18n.t('orderDetail.title.startDateTime'),
          i18n.t('orderDetail.title.endDateTime'),
          i18n.t('orderDetail.title.carRate'),
          i18n.t('orderDetail.order.insurance'),
          i18n.t('orderDetail.order.process'),
        ]
      case OrderStatus.DELIVERED:
        return [
          i18n.t('orderDetail.order.number'),
          i18n.t('orderDetail.order.time'),
          i18n.t('orderDetail.order.car'),
          i18n.t('orderDetail.order.store'),
          i18n.t('orderDetail.title.startDateTime'),
          i18n.t('orderDetail.title.endDateTime'),
          i18n.t('orderDetail.title.carRate'),
        ]
      case OrderStatus.COMPLETE:
        return [
          i18n.t('orderDetail.order.number'),
          i18n.t('orderDetail.order.time'),
          i18n.t('orderDetail.order.car'),
          i18n.t('orderDetail.order.store'),
          i18n.t('orderDetail.title.startDateTime'),
          i18n.t('orderDetail.title.endDateTime'),
          i18n.t('orderDetail.title.carRate'),
        ]
      case OrderStatus.CANCELED:
        return [
          i18n.t('orderDetail.order.number'),
          i18n.t('orderDetail.order.time'),
          i18n.t('orderDetail.order.car'),
          i18n.t('orderDetail.order.store'),
          i18n.t('orderDetail.title.carRate'),
          i18n.t('orderDetail.order.refund.rate'),
          i18n.t('orderDetail.order.refund.price'),
        ]
      case OrderStatus.HOLD:
        return [
          i18n.t('orderDetail.order.number'),
          i18n.t('orderDetail.order.time'),
          i18n.t('orderDetail.order.car'),
          i18n.t('orderDetail.order.store'),
          i18n.t('orderDetail.title.startDateTime'),
          i18n.t('orderDetail.title.endDateTime'),
          i18n.t('orderDetail.title.carRate'),
        ]
      default:
        return []
    }
  })

  const orders = ref<any[]>([])

  const fetchOrderList = async (status: OrderStatus) => {
    initOrders()
    const res = await getOrders()

    orders.value = res.filter(order => order.status === status)
      .filter(order => !isHiddenOrder(order))
  }

  const initOrders = () => { orders.value = [] }

  watch(statusRef, async (status) => {
    await fetchOrderList(status)
  }, { immediate: true })

  // 過濾掉未成功付款且用 TapPay 或 Payments91App 付款的訂單
  const isHiddenOrder = (order: Order) => {
    return order.paid !== PaymentStatus.SUCCESS && (order.paymentType === PaymentType.TAPPAY || order.paymentType === PaymentType.PAYMENTS91APP)
  }

  return {
    headers,
    orders,
    fetchOrderList,
  }
}
