









import { useContext } from '@nuxtjs/composition-api'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup () {
    const { store, query } = useContext()

    const isFetchingUser = computed(() => store?.state.user.isFetching)
    const isLogon = computed(() => store?.state.user.user)

    const isReferral = query.value.referral === 'true'

    return {
      isFetchingUser,
      isLogon,
      isReferral,
    }
  },
})
