import { render, staticRenderFns } from "./OrderTableRowHold.vue?vue&type=template&id=2a73d94f&lang=pug&"
import script from "./OrderTableRowHold.vue?vue&type=script&lang=ts&"
export * from "./OrderTableRowHold.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrderTableCellOrderNum: require('/app/components/order/table/cell/OrderTableCellOrderNum.vue').default,OrderTableCell: require('/app/components/order/table/cell/OrderTableCell.vue').default,OrderTableCellText: require('/app/components/order/table/cell/OrderTableCellText.vue').default,OrderTableCellLink: require('/app/components/order/table/cell/OrderTableCellLink.vue').default})
